import myAxiosBackend from "@/axios";
import Calculation from "@/models/Calculation";
import IndividualItem from "@/models/IndividualItem";
import myApi from "@/api/api";

const CalculationStoreModule = {
  namespaced: true,
  state() {
    return {
      calculation: null,
      materialList: null,
      materialUpdateAt: null,
      calculationResult: null,
      individualItemList: null
    }
  },
  mutations: {
    setCalculation(state, payload) {
      state.calculation = new Calculation(payload);
    },
    setMaterialList(state, payload) {
      state.materialList = payload;
    },
    setMaterialUpdatedAt(state, payload) {
      state.materialUpdateAt = payload;
    },
    setCalculationResult(state, payload) {
      state.calculationResult = payload;
    },
    setIndividualItems(state, payload) {
      const result = []
      for (let i = 0; i < payload.length; i++) {
        result.push(new IndividualItem(payload[i]))
      }
      state.individualItemList = result
    }
  },
  actions: {
    listCalculation: (context, payload) => {
      return myAxiosBackend.get('/api/v1/list_calculation_for_index',
        {params: {'page': payload.page, 'per_page': payload.per_page, 'search': payload.search}})
        .then((response) => {
          if (response.statusText === "OK") {
            return response.data
          } else {
            return null
          }
        })
    },
    deleteCalculation: (context, uid) => {
      const url = new URL('/api/v1/delete_calculation', myAxiosBackend.defaults.baseURL);
      url.search = new URLSearchParams({cid: uid});

      return myAxiosBackend.delete(url)
        .then((response) => {
          if (response.statusText === "OK") {
            return response.data
          } else {
            return null
          }
        })
    },
    setCalculationById(context, cid) {
      return myAxiosBackend.get('/api/v1/get_calculation', {params: {'cid': cid}})
        .then((response) => {
          if (response.statusText === 'OK') {
            context.commit('setCalculation', response.data)
          } else {
            context.commit('setCalculation', null)
          }
          return response.data
        })
    },
    loadMaterialList(context) {
      myAxiosBackend.get('/api/v1/list_materials')
        .then(response => response.statusText === 'OK' ?
          (context.commit('setMaterialList', response.data), context.commit('setMaterialUpdatedAt', new Date())) :
          context.commit('setMaterialList', null), context.commit('setMaterialUpdatedAt', null))
    },
    createNewCalculation: (context) => {
      return myAxiosBackend.post('/api/v1/new_calculation')
        .then((response) => {
          if (response.statusText === 'OK') {
            context.commit('setCalculation', response.data)
          }
          return response.data
        })
    },
    copyCalculation: (context, uid) => {
      const url = new URL('/api/v1/copy_calculation', myAxiosBackend.defaults.baseURL);
      url.search = new URLSearchParams({cid: uid});

      return myAxiosBackend.post(url)
        .then((response) => {
          return response.data
        });
    },
    updateCalculation: (context, payload) => {
      return myAxiosBackend.patch('/api/v1/update_calculation', payload)
        .then((response) => {
          // context.commit('setCalculation', response.data)
          return response.data
        });
    },
    calculateCalculation: (context, payload) => {
      const url = new URL('/api/v1/calculate_calculation', myAxiosBackend.defaults.baseURL);
      url.search = new URLSearchParams({cid: payload.cid,
        vat: payload.vat,
        cutoff: payload.cutoff,
        dimensions: payload.dimensions,
        sale: payload.sale});

      return myAxiosBackend.get(url)
        .then((response) => {
          if (response.statusText === "OK") {
            context.commit('setCalculationResult', response.data)
            return response.data
          }
        })
    },
    individualItemList: async (context, cid) =>  {
      const result = await myApi.listIndividualItems(cid);
      context.commit('setIndividualItems', result)
      return result
    }
  },
  getters: {
    getCalculation(state) {
      return state.calculation
    },
    getMaterialList(state) {
      return state.materialList
    },
    getMaterialUpdatedAt(state) {
      return state.materialUpdateAt
    },
    getIndividualItems(state) {
      return state.individualItemList
    }
  }
};

export default CalculationStoreModule;