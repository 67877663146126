import store from "@/store";
import router from "@/router";

const myAxiosBackend = require('axios').default;
myAxiosBackend.defaults.baseURL = process.env.VUE_APP_API_HOST;


myAxiosBackend.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Neautorizovaný přístup, odhlásit uživatele a přesměrovat na login page
      store.commit('logout');
      router.push('/login')
    }
    return Promise.reject(error);
  }
);

export default myAxiosBackend;