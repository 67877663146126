import myAxiosBackend from "@/axios";

class Api {
  async getPriceListItem(itemId) {
    try {
      const response = await myAxiosBackend.get(`/api/v1/price_list_item/${itemId}`);
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async allUsedMaterials(cid) {
    try {
      const response = await myAxiosBackend.get('/api/v1/all_used_materials', {params: {'calculation_id': cid}});
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async lastUsedMaterial(cid) {
    try {
      const response = await myAxiosBackend.get('/api/v1/last_used_material', {params: {'calculation_id': cid}});
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async listShapes() {
    try {
      const response = await myAxiosBackend.get('/api/v1/list_shapes');
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async listIndividualItems(cid) {
    try {
      const response = await myAxiosBackend.get('/api/v1/individual_items', {params: {cid: cid}});
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async InsertIndividualItems(payload) {
    try {
      const response = await myAxiosBackend.post('/api/v1/individual_items', payload);
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async deleteIndividualItem(item_id){
    try {
      const response = await myAxiosBackend.delete(`/api/v1/individual_items/${item_id}`);
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async changePassword(oldPasswd, newPasswd) {
    try {
      const response = await myAxiosBackend.post('/api/v1/change_password', {oldPassword: oldPasswd, newPassword: newPasswd});
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async calcualteCalculation(cid, vat, cutoff){
    try {
      const response = await myAxiosBackend.get('/api/v1/calculate_calculation_new', {params: {'cid': cid, 'vat': vat, 'cutoff': cutoff}});
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async changeMaterialForAllItems(cid, materialId) {
    try {
      const response = await myAxiosBackend.post('/api/v1/update_all_calculation_items_material',
        {cid: cid, material_id: materialId})
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}

const myApi = new Api();

export default myApi;