<template>
  <div class="card myTopMargin">
    <div class="card-header" id="calculationItemsHeading">
      <div class="row my-2">
        <h3>Zadání dílců pro výrobu</h3>
      </div>
    </div>
    <div class="card-body row g-3">
      <div class="row gy-3">
        <div class="form-group">
          <NewItemButtons :cid="cid"/>
          <div class="float-end position-relative">
            <button class="btn btn-warning mx-1" @click="materialModalVisible = true"> Změna materiálu </button>
            <span class="position-absolute end-0 top-15 translate-middle-y me-1" data-bs-toggle="tooltip"
                    data-bs-placement="right" title="Provede změnu materiálu u všech dílců kalkulace.">
                <i class="bi bi-question-circle"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="row gy-3">
        <h5 v-if="listCalculationItems.length > 0">Seznam dílů:</h5>
        <div class="row g-0">
          <CalculationItemDetailList v-for="(item, key) in listCalculationItems" :key="key" :item-detail="item"
                                     @item-update="itemsUpdated"/>
        </div>
      </div>
    </div>

  </div>
  <CalculationItemMaterialModal :is-visible="materialModalVisible" :cid="cid"
                                @modal-status="modalStatus" @selected-material="setSelectedMaterial"/>
</template>
<script>
import CalculationItemDetailList from "@/components/items/CalculationItemDetailList.vue";
import NewItemButtons from "@/components/calculation/NewItemButtons.vue";
import CalculationItemMaterialModal from "@/components/items/CalculationItemMaterialModal.vue";
import myApi from "@/api/api";
import router from "@/router";

export default {
  name: "CalculationItems",
  emits: ['item-update'],
  components: {
    CalculationItemMaterialModal,
    CalculationItemDetailList,
    NewItemButtons
  },
  props: ['cid', 'listCalculationItems'],
  data() {
    return {
      materialModalVisible: false
    }
  },
  methods: {
    itemsUpdated() {
      this.$emit('item-update')
    },
    modalStatus(n) {
      if (n === 'closed' || n === 'saved') {
        this.materialModalVisible = false
      }
    },
    async setSelectedMaterial(n) {
      await myApi.changeMaterialForAllItems(this.cid, n.materialId);
      await router.push({name: 'calculationEdit', query: {cid: this.cid}});
      router.go(0)
    }
  }
}
</script>
<style scoped>
.top-15 {
  top: -40%;
}
</style>