<template>
  <section>
    <div class="container py-5">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card shadow" style="border-radius: 1rem;">
            <div class="card-body p-5 text-center">
              <form @submit.prevent="submitLogin">
                <h3 class="mb-5">Přihlášení uživatele</h3>
                <div class="form-floating mb-4">
                  <input type="text" id="userName" class="form-control form-control-lg" v-model="userName"/>
                  <label class="form-label" for="tuserName">Uživatelské jméno</label>
                </div>

                <div class="form-floating mb-4">
                  <input type="password" id="userPassword" class="form-control form-control-lg"
                         v-model="userPassword"/>
                  <label class="form-label" for="userPassword">Heslo</label>
                </div>
                <div v-if="errorShow" class="row my-3">
                  <p class="text-danger">Zkontroluj přihlašovací údaje...</p>
                </div>
                <div class="row d-flex justify-content-end">
                  <div class="col-auto">
                    <button class="btn btn-primary btn-block" type="submit">Přihlásit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import router from "@/router";

export default {
  name: "loginForm",
  data() {
    return {
      userName: '',
      userPassword: '',
      errorShow: false,
      showPassword: false,
    }
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  methods: {
    submitLogin() {
      this.errorShow = false;
      this.$store.dispatch('loginUser', {username: this.userName, password: this.userPassword})
          .then(() => {
            router.push('/');
            router.go(0);
          })
          .catch(() => {
            this.errorShow = true;
          })
    },
  }
}
</script>


<style scoped>

</style>