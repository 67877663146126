<template>
  <div class="row mx-1 mb-2">
    <div class="col col-8">
      <b>Název materiálu:</b>
    </div>
    <div class="col col-2">
       <b>Rozměr - šířka (mm):</b>
    </div>
    <div class="col col-2 position-relative">
      <b>Rozměr - výška (mm):</b>
      <span class="position-absolute end-0 translate-middle-y me-3" data-bs-toggle="tooltip" style="top: -15%;"
            data-bs-placement="right" title="Umožňuje nastavit jiné rozměry desky materiálu než jsou uvedeny v databázi. Tyto rozměry budou použity pro výpočet plochy desky a dořezů. Takto upravený údaj se nikde neukládá a při dalším výpočtu kalkulace bude výchozí hodnota převzata z databáze.">
      <i class="bi bi-question-circle"></i>
      </span>
    </div>
  </div>
  <div class="row mx-1 mb-1" v-for="(material, key) in usedMaterials" :key="key">
    <div class="col col-8">
      <input class="form-control" type="text" id="material-name" name="material-name" :value="materialNameParse(material)" disabled/>
    </div>
    <div class="col col-2">
      <input class="form-control" type="number" id="size-x" name="size-x" :value="material.dimension_x" @change="inputDataUpdate($event, material.id, 'x')"/>
    </div>
    <div class="col col-2 position-relative">
      <input class="form-control" type="number" id="size-y" name="size-y" :value="material.dimension_y" @change="inputDataUpdate($event, material.id, 'y')"/>
    </div>
  </div>

</template>

<script>
import myApi from "@/api/api";

export default {
  name: "CalculationMaterialSizeSetter",
  props: ['cid'],
  emits: ['material-dimensions'],
  data() {
    return {
      usedMaterials: [],
      inputData: Object
    }
  },
  methods: {
    materialNameParse(item) {
      return `${item.name}, ${item.surface}, ${item.thickness.name}, ${item.material.name}, ${item.price_list.name}`
    },
    convertToInputData(data) {
      return data.reduce((result, item) => {
          result[item.id] = {
              x: item.dimension_x,
              y: item.dimension_y
          };
          return result;
      }, {});
    },
    inputDataUpdate(event, material_id, key) {
      this.inputData[material_id][key] = parseInt(event.target.value);
    }
  },
  watch: {
    inputData: {
      handler(newVal) {
        this.$emit('material-dimensions', newVal)
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    const data = await myApi.allUsedMaterials(this.cid);
    this.inputData = this.convertToInputData(data)
    this.usedMaterials = data;
  }

}
</script>

<style scoped>
</style>