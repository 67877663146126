<template>
  <div class="row justify-content-between">
    <div class="col-auto my-5">
      <h1>Seznam kalkulací</h1>
    </div>
    <div class="col-auto my-5">
      <button class="btn btn-outline-success btn-lg" @click="calculationNew">
        <i class="bi-plus-circle-dotted"></i>
        <strong>&nbsp;&nbsp;Nová kalkulace</strong>
      </button>
    </div>
  </div>
  <div class="row my-3">
    <div class="col-auto d-flex align-items-center">
      <input type="text" class="form-control me-3" id="search" name="search" v-model="searchText" placeholder="hledaný text...">
      <i class="bi bi-x-circle fs-5 hover-icon" @click="resetSearch()" ></i>
    </div>
  </div>
  <table class="table table-light table-striped table-hover">
    <thead class="table-head">
    <tr>
      <th>Název</th>
      <th>Číslo</th>
      <th>Datum vytvoření</th>
      <th>Status</th>
      <th>Akce</th>
    </tr>
    </thead>
    <tbody>
    <tr class="align-middle" v-for="(item, key) in calculationList" :key="key" @click="handleRowClick(item.uid, $event)">
      <td v-html="highlightText(item.name, searchText)"></td>
      <td v-html="highlightText(item.number, searchText)"></td>
      <td> {{ item.created_at }} </td>
      <td> {{ item.status }}</td>
      <td>
        <div class="btn-group" role="group">
          <a class="btn btn-primary" @click.stop="calculationEdit(item.uid)">Editovat</a>
          <a class="btn btn-warning" @click.stop="calculationCopy(item.uid)">Vytvoř kopii</a>
          <a class="btn btn-danger" @click.stop="calculationDelete(item.uid)">Smazat</a>
        </div>
        <a class="btn btn-success mx-3" @click="calculationCalculate(item.uid)">Spočítej</a>
        <a v-if="!!item.pdf_file" class="btn btn-info" :href="calculationHRefs(item.uid).pdf" target="_blank">PDF</a>
      </td>
    </tr>
    </tbody>
  </table>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-end">
      <li class="page-item "><a class="page-link" href="#" @click.prevent="setFirstPage()"> &lt;&lt; </a></li>
      <li class="page-item "><a class="page-link" href="#" @click.prevent="moveBackwardPage()"> &lt; </a></li>
      <li class="page-item" v-for="n in pagination.total_pages" :key="n" :class="{ active: n === currentPage }">
        <a class="page-link" href="#" @click.prevent="setCurrentPage(n)">{{ n }}</a>
      </li>
      <li class="page-item "><a class="page-link" href="#" @click.prevent="moveForwardPage()"> &gt; </a></li>
      <li class="page-item "><a class="page-link" href="#" @click.prevent="setLastPage()"> &gt;&gt; </a></li>
      <li style="margin-left: 10px;">
        <select class="form-select" v-model="perPage">
           <option v-for="per_page in perPageSelect" :key="per_page" :value="per_page"> {{ per_page }}</option>
        </select>
      </li>
    </ul>
  </nav>
</template>

<script>
import router from "@/router";

export default {
  name: "CalculationList",
  data() {
    return {
      calculationList: [],
      pagination: {},
      currentPage: 1,
      perPage: 20,
      perPageSelect: [10, 20, 50, 100, 500],
      searchText: ""
    }
  },
  methods: {
    listCalculation() {
      this.isMaterialLoaded = false
      this.$store.dispatch('calculation/listCalculation', {page: this.currentPage, per_page: this.perPage, search: this.searchText})
          .then((result) => {
            this.isMaterialLoaded = true
            this.pagination = result.pagination
            this.calculationList = result.data
          })
    },
    calculationHRefs(cid) {
      const response = {};
      response["edit"] = '/edit?cid=' + cid
      response['copy'] = '/copy?cid=' + cid
      response['delete'] = '/delete?cid=' + cid
      response['calculate'] = '/calculate?cid=' + cid
      response['pdf'] = this.URL_API_BASE + '/download?cid=' + cid
      return response
    },
    calculationEdit(uid) {
      router.push({
        name: 'calculationEdit',
        query: {cid: uid}
      })
    },
    calculationCopy(uid) {
      this.$store.dispatch('calculation/copyCalculation', uid)
          .then((result) => {
            if (result) {
              this.listCalculation();
            }
          })
    },
    calculationDelete(uid) {
      this.$store.dispatch('calculation/deleteCalculation', uid)
          .then((result) => {
            if (result.status) {
              this.listCalculation();
            }
          })
    },
    calculationCalculate(uid) {
      router.push({
        name: 'calculationCalculate',
        query: {cid: uid}
      })
    },
    calculationNew() {
      this.$store.dispatch('calculation/createNewCalculation')
          .then((result) => {
            router.push({
              name: 'calculationEdit',
              query: {cid: result.uid}
            })
          })
    },
    setCurrentPage(n) {
      this.currentPage = n;
    },
    setFirstPage() {
      this.currentPage = 1
    },
    setLastPage() {
      this.currentPage = this.pagination.total_pages
    },
    moveForwardPage() {
      if (this.currentPage < this.pagination.total_pages) {
        this.currentPage += 1;
      }
    },
    moveBackwardPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    resetSearch() {
      this.searchText = "";
    },
    handleRowClick(uid, event) {
      if (event.target.tagName.toLowerCase() === 'a' || event.target.closest('a')) {
        return;
      }
      this.calculationEdit(uid);
    },
    highlightText(text, search) {
      if (!search) return text;

      const removeDiacritics = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      };

      // Escape special regex characters
      const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      };

      // Remove diacritics from search term and escape regex special characters
      const searchNoDiacritics = escapeRegExp(removeDiacritics(search));

      // Create array to store text parts
      const parts = [];
      let lastIndex = 0;

      // Get normalized form of text for searching
      const normalizedText = removeDiacritics(text);

      // Create case-insensitive regex
      const regex = new RegExp(searchNoDiacritics, 'gi');

      // Find all matches in normalized text
      let match;
      while ((match = regex.exec(normalizedText)) !== null) {
        const matchStart = match.index;
        const matchEnd = matchStart + match[0].length;

        // Add text before match
        if (matchStart > lastIndex) {
          parts.push(text.slice(lastIndex, matchStart));
        }

        // Add highlighted original text that corresponds to the match
        parts.push(`<span class="my-highlight">${text.slice(matchStart, matchEnd)}</span>`);

        lastIndex = matchEnd;
      }

      // Add remaining text
      if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
      }

      return parts.join('');
    }
  },
  watch: {
    perPage: {
      handler() {
        this.listCalculation();
      },
      deep: true,
      immediate: true
    },
    currentPage: {
      handler() {
        this.listCalculation();
      },
      deep: true,
      immediate: true
    },
    searchText: {
      handler() {
          this.listCalculation();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {},
  mounted() {
    // this.listCalculation()
  }
}
</script>
<style>
.my-highlight {
  background-color: yellow;
}
</style>
<style scoped>
.hover-icon:hover {
  color: dodgerblue;
}

</style>